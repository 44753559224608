import React, {useContext, useState} from "react";
import Select from 'react-select';
import MediaQuery from "react-responsive/src";
import {getStore} from '../../store';
import {hexToRgb} from "../../services/utils.service";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { TranslationContext } from "../../context/TranslationContext";
import {initialChoiceMade} from '../../actions/uiActions';
import { useDispatch } from 'react-redux'

const getList=(theColors) =>{
  let tab = [];
  tab.push({label:"Tous",value:null});
  theColors && theColors.forEach(color=> tab.push({label:color,value:color}));
  return tab;
}
const getSelectedColor = (color)=>{
  if (color) return {label:`Filtrer: ${color}`,value:color};
  else return {label:`Filtrer: Tous`,value:color};
};

const changeSelect = (option,filter)=> filter(option.value);

export const FgraniteColors=({filter,currentColor, selectedGranite, currentSearch,hideTitle,items, designType, family, mainColor})=>{
    const [currentHoverColor, setCurrentHoverColor] = useState(null);
    
    let searched = currentSearch;
   
    const handleOnSearch = (string, results) => {
      searched = string;
      setCurrentHoverColor(null)
      !family
    }
  
    const handleOnSelect = (item) => {
      // the item selected
      searched = item.name;
      filter(null,searched,false);
      setCurrentHoverColor(null)
      !family
    }
    const handleOnHover = (elt) => {
      if (!designType) {
        setCurrentHoverColor(elt.name)
        if (document.getElementById("matchingColors")) document.getElementById("matchingColors").style.display = "flex"
      }
    }
  
    const handleOnClear = () => {
      // the item selected
      searched = null;
      filter(null,searched,false);
      setCurrentHoverColor(null)
      !family
    }

    const onSearchGraniteBtn = () => {
      // get autocomplet input
      const inputFeild = document.querySelector('._FfilterGranit input').value;
      
      if ( inputFeild && inputFeild.length > 1 && searched && searched.length > 1) {
        filter(null, searched,false);
      } else {
        handleOnClear();
      }
    }
    const dispatch = useDispatch();
    let maincolors = null;
    let secondarycolors = null;
    let theColors = getStore().getState().configurator.current.options.granitcolors
    let matchcolors = getStore().getState().configurator.current.options.matchcolors
    const currentColorId = theColors.find(color=> color.name === currentHoverColor)?.id
    const currentColorInfo = theColors.find(color=> color.id === currentColorId)
    const isBicolorConfig = getStore().getState().configurator.current.configuration.bicolorMonument
    theColors && theColors.map(elt => {
      // colorinrgb[colorinrgb.indexOf(Math.max(...colorinrgb))] -= 15
      let colorinrgb = hexToRgb(elt.color)
      const color = "rgb("+colorinrgb[0]+","+colorinrgb[1]+","+colorinrgb[2]+",1)";
      const color2 ="rgb("+colorinrgb[0]+","+colorinrgb[1]+","+colorinrgb[2]+",0.4)"
      colorinrgb = colorinrgb.map(x => x - 75);
      const color1 ="rgba("+colorinrgb[0]+","+colorinrgb[1]+","+colorinrgb[2]+", 0.9)"
      elt.backcolor = "linear-gradient(0deg,"+color1+" 3%, "+color+" 40%, "+color2+" 90%)"
    })
    
    if (isBicolorConfig) {
      maincolors = matchcolors && matchcolors.filter(matchcolor=> 
        currentColorInfo && parseInt(matchcolor.mainColor) === currentColorId && parseInt(matchcolor.secondaryColor) !== currentColorId)
        .map(elt => {
          const colorElt = theColors.find(color=> color.id === parseInt(elt.secondaryColor))
        // let colorinrgb = hexToRgb(colorElt.color)
        // const color = "rgb("+colorinrgb[0]+","+colorinrgb[1]+","+colorinrgb[2]+",1)";
        // const color2 ="rgb("+colorinrgb[0]+","+colorinrgb[1]+","+colorinrgb[2]+",0.4)"
        // colorinrgb = colorinrgb.map(x => x - 75);
        // const color1 ="rgba("+colorinrgb[0]+","+colorinrgb[1]+","+colorinrgb[2]+", 0.9)"
        elt.value = `${colorElt.color}/${currentColorInfo.color}`
        elt.name = `${colorElt.name}/${currentColorInfo.name}`
        elt.order = colorElt.order
        elt.backcolor = `repeating-conic-gradient( from 40deg, ${currentColorInfo.color} 0deg calc(3.6deg * 50), ${colorElt.color} 50deg 360deg )`
        return elt
      })

      secondarycolors = matchcolors && matchcolors.filter(matchcolor=> 
        currentColorInfo && parseInt(matchcolor.secondaryColor) === currentColorId && parseInt(matchcolor.mainColor) !== currentColorId)
        .map(elt => {
          const colorElt = theColors.find(color=> color.id === parseInt(elt.mainColor))
        // let colorinrgb = hexToRgb(colorElt.color)
        // const color = "rgb("+colorinrgb[0]+","+colorinrgb[1]+","+colorinrgb[2]+",1)";
        // const color2 ="rgb("+colorinrgb[0]+","+colorinrgb[1]+","+colorinrgb[2]+",0.4)"
        // colorinrgb = colorinrgb.map(x => x - 75);
        // const color1 ="rgba("+colorinrgb[0]+","+colorinrgb[1]+","+colorinrgb[2]+", 0.9)"
          elt.value = `${currentColorInfo.color}/${colorElt.color}`
          elt.name = `${currentColorInfo.name}/${colorElt.name}`
          elt.order = colorElt.order
          elt.backcolor = `repeating-conic-gradient( from 40deg, ${colorElt.color} 0deg calc(3.6deg * 50), ${currentColorInfo.color} 50deg 360deg )`
          return elt
      })
    }

    let currentGranite = currentColor && currentColor.split("/")[0]
    if (selectedGranite === "secondary") {
      currentGranite = currentColor.split("/")[1]
    } else if (selectedGranite === "primary") {
      currentGranite = currentColor.split("/")[0]
    }

    const t = useContext(TranslationContext);
    return (
      <div className="fGraniteColorsContainer">
        <div className={`FfilterCatalog GraniteColors colors${designType ? designType : 'X'}`}>
          <div className="fFilterGraniteContainer">
              {/* <div className={`${designType !="B" ? 'flex-25' : ''}`}></div> */}
              {!designType && <div className={`_FfilterGranit ${!designType ? 'flex-25' : ''}`}>
                <div style={{width: "250px"}}>
                    <ReactSearchAutocomplete
                      items={items}
                      onSearch={handleOnSearch}
                      onSelect={handleOnSelect}
                      onClear={handleOnClear}
                      inputSearchString={searched || ''}
                      placeholder={ t('config3d_monument_modal_granit') || "GRANIT..." }
                      showIcon={true} 
                      styling={{ 
                        height: "31px", 
                        boxShadow: "none",
                        border: "1px solid #D1D5DB",
                        borderRadius: "7px"
                      }}
                      fuseOptions={{ caseSensitive: false , threshold: 0.2, sortFn: (a, b) => (a.name - b.name), minMatchCharLength: 2, keys: [ "name", "name2"] }}
                      autoFocus
                    />
                </div>
                {/* <button className="AllColors" onClick={() => onSearchGraniteBtn()}>Rechercher</button> */}
              </div>}

              <div className={`${!designType ? 'flex-75 d-flex align-items-center flex-wrap justify-content-center' : 'flex-100'}`}>
                {!hideTitle && <div className="TitrePage">Sélectionnez un granit</div>}
                <div style={{position: "relative"}}>
                  <div>
                  { theColors && 
                    theColors.map(elt => 
                      <button key={elt.name} 
                        className={`colorFilter ${currentColor == elt.name ? 'current' : ''} ${!family ? 'extrColor' : 'famColor'} ${!family && currentGranite === elt.name ? 'has-value' : ''}`} 
                        style={{backgroundColor: elt.color}}
                        onClick={()=> {
                          searched = null;
                          filter(elt.name,searched,false)
                        }}
                        onMouseEnter={()=> handleOnHover(elt)}
                        data-gtm={elt.name}>
                          {designType ? elt.name : ''}
                      </button>
                    )
                  }
                  </div>
                  { isBicolorConfig && secondarycolors && maincolors && currentHoverColor && !designType &&
                    <div className="matchingColors" id="matchingColors">
                      <div className="theColor">
                        <button key={`${currentHoverColor}/${currentHoverColor}`} 
                            className={`colorFilter ${currentColor === `${currentHoverColor}/${currentHoverColor}` ? 'current' : ''} ${!family ? 'extrColor' : 'famColor'} ${!family && currentColor === `${currentHoverColor}/${currentHoverColor}` ? 'has-value' : ''}`} 
                            style={{backgroundColor: currentColorInfo?.color}}
                            onClick={()=> {
                              searched = null;
                              filter(`${currentHoverColor}/${currentHoverColor}`,searched,true, null)
                            }}
                            data-gtm={currentColor}>
                              {designType ? currentColor : ''}
                        </button>
                      </div>
                      <div>
                        <div className="secondaryColor">
                          { secondarycolors && 
                          secondarycolors.sort((p1, p2) => parseInt(p1.order) - parseInt(p2.order)).map(elt => 
                            <button key={elt.name} 
                              className={`colorFilter ${currentColor == elt.name ? 'current' : ''} ${!family ? 'extrColor' : 'famColor'} ${!family && currentColor === elt.name ? 'has-value' : ''}`} 
                              style={{background: elt.backcolor}}
                              onClick={()=> {
                                searched = null;
                                filter(elt.name,searched,false,"primary")
                              }}
                              data-gtm={elt.name}>
                                {designType ? elt.name : ''}
                            </button>
                          )
                          }
                        </div>
                        <div className="mainColor">
                          { maincolors && 
                          maincolors.sort((p1, p2) => parseInt(p1.order) - parseInt(p2.order)).map(elt => 
                            <button key={elt.name} 
                              className={`colorFilter ${currentColor == elt.name ? 'current' : ''} ${!family ? 'extrColor' : 'famColor'} ${!family && currentColor === elt.name ? 'has-value' : ''}`} 
                              style={{background: elt.backcolor}}
                              onClick={()=> {
                                searched = null;
                                filter(elt.name,searched, false, "secondary")
                              }}
                              data-gtm={elt.name}>
                                {designType ? elt.name : ''}
                            </button>
                          )
                          }
                        </div>
                      </div>
                      
                    </div>
                  }
                </div>
                <button 
                  className="flex-start reset-filter text-cta secondary1 custom-secondary-button button-primary button-small text-cta" 
                  onClick={()=>
                    handleOnClear()
                    // filter(null,searched)
                  }>
                    Réinitialiser
                  </button>

                  <span className="flex-start tooltip top" tooltip-text="Appliquer un granit à tous le catalogue" style={{paddingTop: '4px'}}>
                  <span className="infos-icon icon-large secondary2-icon"></span>
                </span>
              </div>
          </div>

        </div>
        {/*<div> */}
        {/*<Select className="selectColor" value={getSelectedColor(currentColor)} options={getList(theColors)}*/}
        {/*  onChange={(option)=>changeSelect(option,filter)}  isSearchable={false}/>*/}
        {/*</div>*/}
        {designType && <div className="goToInitChoiceBtn" style={{"--f-main-color":mainColor}} onClick={()=> window.history.back()}><span>Annuler</span><i className="icon material-icons">close</i></div>}
      </div>
    );
}


